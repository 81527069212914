export const eventsData = [
  [
    {
      name: 'Claw Convoy',
      pos: '0% 35%',
      zoom: '200%',
      image: 'Claw Convoy 2.png',
      start: '2024-11-22 10:00:00',
      end: '2024-12-02 03:59:59',
      color: '#ebf97b',
      url: 'https://www.hoyolab.com/article/35053992',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Iktomi Spiritseeking Scrolls',
      pos: '0% 35%',
      zoom: '100%',
      image: 'Iktomi Spiritseeking Scrolls.jpg',
      start: '2024-11-28 10:00:00',
      end: '2024-12-16 03:59:59',
      color: '#6583e2',
      url: 'https://www.hoyolab.com/article/35015809',
      showOnHome: true,
    },
    {
      name: 'Exercise Surging Storm',
      pos: '0% 0%',
      zoom: '200%',
      image: 'Exercise Surging Storm.png',
      start: '2024-12-18 10:00:00',
      end: '2024-12-30 03:59:59',
      color: '#e5b88a',
      url: 'https://www.hoyolab.com/article/35393996',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Of Fading Flowers and Falling Feathers',
      pos: '0% 15%',
      zoom: '200%',
      image: 'Of Fading Flowers and Falling Feathers.png',
      start: '2024-11-20 06:00:00',
      end: '2024-12-10 17:59:59',
      color: '#94c3e1',
      url: 'https://www.hoyolab.com/article/35015714',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: "Adventurer's Trials: Metamorphosis",
      pos: '0% 15%',
      zoom: '200%',
      image: 'Adventurers Trials Metamorphosis.png',
      start: '2024-12-11 10:00:00',
      end: '2024-12-23 03:59:59',
      color: '#8dc2f2',
      url: 'https://www.hoyolab.com/article/35477560',
      showOnHome: true,
    },
    {
      name: 'Ley Line Overflow',
      pos: '0% 20%',
      image: 'leyline_overflow.jpg',
      start: '2024-12-23 04:00:00',
      end: '2024-12-30 03:59:59',
      color: '#5baced',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/35393996',
      showOnHome: true,
    },
  ],
  [
    {
      name: "Piercing Shot's Crimson Wake - Chasca Banner",
      pos: '30% 20%',
      zoom: '200%',
      image: 'Piercing Shots Crimson Wake 1.png',
      start: '2024-11-20 06:00:00',
      end: '2024-12-10 17:59:00',
      color: '#fe7391',
      url: 'https://www.hoyolab.com/article/35015713',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Decree of the Deeps - Neuvillette Banner',
      pos: '30% 15%',
      zoom: '200%',
      image: 'Decree of the Deeps 3.png',
      start: '2024-12-10 18:00:00',
      end: '2024-12-31 14:59:00',
      color: '#72fefd',
      url: 'https://www.hoyolab.com/article/35393826',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Conjuring Chiaroscuro - Lyney Banner',
      pos: '30% 20%',
      zoom: '200%',
      image: 'Conjuring Chiaroscuro 3.png',
      start: '2024-11-20 06:00:00',
      end: '2024-12-10 17:59:00',
      color: '#fcc6c8',
      url: 'https://www.hoyolab.com/article/35015713',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Gentry of Hermitage - Zhongli Banner',
      pos: '30% 15%',
      zoom: '200%',
      image: 'Gentry of Hermitage 6.png',
      start: '2024-12-10 18:00:00',
      end: '2024-12-31 14:59:00',
      color: '#D1A55C',
      url: 'https://www.hoyolab.com/article/35393826',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 40%',
      zoom: '200%',
      image: 'Epitome Invocation 73.png',
      start: '2024-11-20 06:00:00',
      end: '2024-12-10 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/35015713',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 40%',
      zoom: '200%',
      image: 'Epitome Invocation 74.png',
      start: '2024-12-10 18:00:00',
      end: '2024-12-31 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/35393826',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-11-16 04:00:00',
      end: '2024-12-16 04:00:00',
      color: '#4299E1',
      description:
        "After the current active character's Charged Attack hits an opponent, said character's ATK increases by 20% for 8s. Max 4 stacks, each stack has an independent duration.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-12-16 04:00:00',
      end: '2025-01-16 04:00:00',
      color: '#4299E1',
      description:
        "All party members' Shield Strength is increased by 20%. While the active character is protected by a Shield, when they deal DMG to opponents, their attacks will unleash a shockwave at the opponents' position that deals True DMG. This can trigger once every 5s.",
    },
  ],
  [
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-11-01 04:00:00',
      end: '2024-12-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Pyro, Electro, and Dendro. Opening Characters: Klee, Xinyan, Yae Miko, Dori, Tighnari, and Kirara',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-12-01 04:00:00',
      end: '2025-01-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Hydro, Electro, and Anemo. Opening Characters: Sangonomiya Kokomi, Xingqiu, Raiden Shogun, Kujou Sara, Jean, and Lynette',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2025-01-01 04:00:00',
      end: '2025-02-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Pyro, Cryo, and Geo. Opening Characters: Diluc, Bennett, Ganyu, Kaeya, Navia, and Noelle',
    },
  ],
  [
    {
      name: 'Battle Pass - Spiritweaver',
      pos: '0% 45%',
      image: 'Spiritweaver.jpg',
      start: '2024-11-20 06:00:00',
      end: '2024-12-30 03:59:59',
      color: '#878cf6',
      url: 'https://www.hoyolab.com/article/35015809',
      timezoneDependent: true,
    },
  ],
];
